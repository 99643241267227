<template>
  <admin>
    <metatag title="Sales Consultants"></metatag>

    <page-header
      :back="{ to: { name: 'dealers-account-maintenance.dealers.index' }, text: trans('Dealers') }"
    >
      <template v-slot:action>
        <v-menu bottom offset-y class="pointer">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :block="$vuetify.breakpoint.smAndDown"
              large
              color="primary"
              exact
              v-bind="attrs"
              v-on="on"
              class="mx-5 mx-md-2 mt-2 mt-md-0 white--text"
            >
              <v-icon small left>mdi-account-plus-outline</v-icon>
              Add new
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'dealers-account-maintenance.sales-consultants.create' }">
              <v-list-item-title>
                <v-icon small left>mdi-account-plus-outline</v-icon>
                <span class="text-center px-8 py-1">Individual</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'dealers-account-maintenance.sales-consultants.upload' }"
            >
              <v-list-item-title>
                <v-icon small left>mdi-cloud-upload</v-icon>
                <span class="text-center px-8 py-1">Upload via excel</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu 
        ref="toolbar"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :options.sync="options"
          :headers="sales_consultants.headers"
          :items="sales_consultants.data"
          color="primary"
          item-key="id"
          class="text-no-wrap"
          :loading="loading"
          :items-per-page="10"
        >
          <template v-slot:item.id="{ item }">
              <v-btn 
                :to="{ name: 'dealers-account-maintenance.sales-consultants.edit', params: { id: item.id, dealer_id: $route.params.id } }"
                text 
                color="primary" 
                icon>
                {{ item.id }}
              </v-btn>
          </template>
          <template v-slot:item.crm_id="{ item }">
            <h3>
              <code>{{ item.crm_id }}</code>
            </h3>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip v-if="item.status == true" class="ma-2" color="green" text-color="white">
              active
            </v-chip>
            <v-chip v-else class="ma-2" color="red" text-color="white"> inactive </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-no-wrap">
              <v-tooltip bottom v-if="!item.is_email_verified">
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click="resendEmailVerification(item)"
                  >
                    <v-icon small>mdi-email-alert</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Resend email verification'"></span>
              </v-tooltip>
              <!-- Edit -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    :to="{
                      name: 'dealers-account-maintenance.sales-consultants.edit',
                      params: { id: item.id, dealer_id: $route.params.id },
                    }"
                    icon
                    v-on="on"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Edit'"></span>
              </v-tooltip>
              <!-- Edit -->
              <!-- Delete -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon @click="confirmDelete(item.id)" v-on="on">
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Delete'"></span>
              </v-tooltip>
              <!-- Delete -->
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  data() {
    return {
      loading: true,
      tabletoolbar: {
        isSearching: false,
        search: null,
      },
      options: {},
      total: 0,
      filters: [
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Date Updated", value: "year" },
      ],
      sales_consultants: {
        per_page: 10,
        headers: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "First Name", align: "left", value: "first_name", class: "text-no-wrap" },
          { text: "Last Name", align: "left", value: "last_name", class: "text-no-wrap" },
          // { text: "Email", value: "email", class: "text-no-wrap" },
          { text: "CRM ID", value: "crm_id", class: "text-no-wrap" },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
          {
            text: "Actions",
            value: "actions",
            class: "muted--text text-no-wrap",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(this.sales_consultants.per_page);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "dealerSalesConsultant/GET_SALES_CONSULTANTS",
      dealer: "dealers/GET_DEALER"
    }),
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getDealer(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions({
      getSalesConsultants: "dealerSalesConsultant/getSalesConsultants",
      getDealer: "dealers/getDealer",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
      deleteSalesConsultant: "dealerSalesConsultant/delete",
      resendEmalVerificationSalesConsultant: "dealerSalesConsultant/resendEmalVerificationSalesConsultant",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(1, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "") {
      const dealer_id = this.$route.params.id ? this.$route.params.id : null;

      this.getSalesConsultants({
        page: page,
        per_page: itemsPerPage,
        q: q,
        dealer_id: dealer_id,
      }).then((data) => {
        this.sales_consultants.data = this.data.data;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    confirmDelete(id) {
      this.showDialog({
        title: trans("Delete User"),
        text: trans("Are you sure you want to delete this user?"),
        buttons: {
          cancel: {
            text: trans("Cancel"),
            callback: () => {
              this.hideDialog();
            },
          },
          action: {
            color: "error",
            text: trans("Delete"),
            callback: async () => {
              const { page, itemsPerPage } = this.options;

              await this.deleteSalesConsultant(id);
              await this.getItems(1, itemsPerPage);
              
              this.hideDialog();
            },
          },
        },
      });
    },

    async resendEmailVerification(item) {
      await this.resendEmalVerificationSalesConsultant({ email: item.email })
    }
  },
};
</script>

<style></style>
