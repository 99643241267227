var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Sales Consultants"}}),_c('page-header',{attrs:{"back":{ to: { name: 'dealers-account-maintenance.dealers.index' }, text: _vm.trans('Dealers') }},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-menu',{staticClass:"pointer",attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-5 mx-md-2 mt-2 mt-md-0 white--text",attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"primary","exact":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-plus-outline")]),_vm._v(" Add new ")],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'dealers-account-maintenance.sales-consultants.create' }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-plus-outline")]),_c('span',{staticClass:"text-center px-8 py-1"},[_vm._v("Individual")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'dealers-account-maintenance.sales-consultants.upload' }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-cloud-upload")]),_c('span',{staticClass:"text-center px-8 py-1"},[_vm._v("Upload via excel")])],1)],1)],1)],1)]},proxy:true}])}),_c('v-card',[_c('toolbar-menu',{ref:"toolbar",attrs:{"items":_vm.tabletoolbar},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"options":_vm.options,"headers":_vm.sales_consultants.headers,"items":_vm.sales_consultants.data,"color":"primary","item-key":"id","loading":_vm.loading,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'dealers-account-maintenance.sales-consultants.edit', params: { id: item.id, dealer_id: _vm.$route.params.id } },"text":"","color":"primary","icon":""}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.crm_id",fn:function(ref){
var item = ref.item;
return [_c('h3',[_c('code',[_vm._v(_vm._s(item.crm_id))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == true)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" active ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" inactive ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[(!item.is_email_verified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.resendEmailVerification(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email-alert")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Resend email verification')}})]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{
                    name: 'dealers-account-maintenance.sales-consultants.edit',
                    params: { id: item.id, dealer_id: _vm.$route.params.id },
                  },"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Edit')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Delete')}})])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }